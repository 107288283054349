define("discourse/plugins/discourse-singfuse/discourse/components/blocks/map", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/lib/icon-library", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _iconLibrary, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef, no-console, no-shadow, guard-for-in, no-async-promise-executor */

  class Map extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "map", [_tracking.tracked]))();
    #map = (() => (dt7948.i(this, "map"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "infoWindow", [_tracking.tracked]))();
    #infoWindow = (() => (dt7948.i(this, "infoWindow"), void 0))();
    loadMap() {
      (g1 => {
        let h1,
          a1,
          k1,
          p1 = "The Google Maps JavaScript API",
          c1 = "google",
          l1 = "importLibrary",
          q1 = "__ib__",
          m1 = document,
          b1 = window;
        b1 = b1[c1] || (b1[c1] = {});
        let d1 = b1.maps || (b1.maps = {}),
          r1 = new Set(),
          e1 = new URLSearchParams(),
          u1 = () => h1 || (h1 = new Promise(async (f1, n1) => {
            await (a1 = m1.createElement("script"));
            e1.set("libraries", [...r1] + "");
            for (k1 in g1) {
              e1.set(k1.replace(/[A-Z]/g, t1 => "_" + t1[0].toLowerCase()), g1[k1]);
            }
            e1.set("callback", c1 + ".maps." + q1);
            a1.src = `https://maps.${c1}apis.com/maps/api/js?` + e1;
            d1[q1] = f1;
            a1.onerror = () => h1 = n1(Error(p1 + " could not load."));
            a1.nonce = m1.querySelector("script[nonce]")?.nonce || "";
            m1.head.append(a1);
          }));
        d1[l1] ? console.warn(p1 + " only loads once. Ignoring:", g1) : d1[l1] = function (f1) {
          for (var _len = arguments.length, n1 = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            n1[_key - 1] = arguments[_key];
          }
          return r1.add(f1) && u1().then(() => d1[l1](f1, ...n1));
        };
      })({
        key: "AIzaSyB7vjTWS5t72NMx7pfIQpjUA1kMVmXCpl4",
        v: "weekly"
      });
      this.initMap();
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMap", [_object.action]))();
    async initMap() {
      const position1 = {
        lat: 1.3521,
        lng: 103.8198
      };
      // Request needed libraries.
      //@ts-ignore
      const {
        Map: Map1
      } = await google.maps.importLibrary("maps");
      this.infoWindow = new google.maps.InfoWindow();
      this.map = new Map1(document.getElementById("map"), {
        zoom: 11,
        center: position1,
        mapId: "e540586731645835",
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ["roadmap", "terrain"]
        },
        streetViewControl: false
      });
      // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      //   const newColorScheme = event.matches ? "dark" : "light";
      // });
      this.args.model.data.forEach(place1 => {
        this.addMarker(place1);
      });
    }
    async addMarker(place1) {
      const markerIcon1 = (0, _iconLibrary.iconHTML)(place1.icon);
      const markerElement1 = document.createElement("div");
      markerElement1.className = "marker";
      markerElement1.innerHTML = markerIcon1;
      const {
        AdvancedMarkerElement: AdvancedMarkerElement1
      } = await google.maps.importLibrary("marker");
      const marker1 = new AdvancedMarkerElement1({
        position: {
          lat: parseFloat(place1.latitude),
          lng: parseFloat(place1.longitude)
        },
        map: this.map,
        title: place1.title,
        content: markerElement1
      });
      marker1.addListener("click", () => {
        this.infoWindow.close();
        const headerElement1 = document.createElement("div");
        headerElement1.className = "marker-window-header";
        headerElement1.innerHTML = place1.title;
        this.infoWindow.setHeaderContent(headerElement1);
        const contentElement1 = document.createElement("div");
        contentElement1.className = "marker-window-content";
        contentElement1.innerHTML = place1.description;
        this.infoWindow.setContent(contentElement1);
        this.infoWindow.open(marker1.map, marker1);
      });
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div id="map" {{didInsert this.loadMap}}></div>
      
    */
    {
      "id": "1I+uVyr3",
      "block": "[[[1,\"\\n    \"],[11,0],[24,1,\"map\"],[4,[32,0],[[30,0,[\"loadMap\"]]],null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/map.js",
      "scope": () => [_didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Map;
});