define("discourse/plugins/discourse-singfuse/discourse/components/blocks/weather-by-area", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Weather extends _component.default {
    get allForecasts() {
      const areaForecasts1 = this.args.model.data.area_forecasts.map(forecast1 => {
        let {
          title: title1,
          description: description1,
          icon: icon1
        } = forecast1;
        description1 = description1.replace(" (Day)", "").replace(" (Night)", "");
        return {
          title: title1,
          description: description1,
          icon: icon1
        };
      });
      const groupedForecasts1 = Object.groupBy(areaForecasts1, _ref => {
        let {
          description: description1
        } = _ref;
        return description1;
      });
      return Object.keys(groupedForecasts1).map(description1 => {
        const forecasts1 = groupedForecasts1[description1];
        const forecastIcon1 = forecasts1[0].icon;
        const count1 = forecasts1.length;
        let text1 = forecasts1.map(_ref2 => {
          let {
            title: title1
          } = _ref2;
          return title1;
        }).join(", ");
        if (count1 === this.args.model.data.area_forecasts.length) {
          text1 = "Everywhere in Singapore";
        }
        return {
          count: count1,
          icon: forecastIcon1,
          description: description1,
          text: text1
        };
      }).sort((a1, b1) => b1.count - a1.count);
    }
    get forecast() {
      return this.allForecasts[0];
    }
    get forecasts() {
      return this.allForecasts.slice(1);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="heading">
          <h3><span>{{dIcon this.forecast.icon}}</span>
            {{this.forecast.description}}</h3>
        </div>
        <div class="content">
          <div class="areas">
            {{this.forecast.text}}
          </div>
        </div>
        {{#each this.forecasts as |forecast|}}
          <div class="forecast">
            <div class="heading">
              <h3>{{dIcon forecast.icon}} {{forecast.description}}</h3>
            </div>
            <div class="areas">
              {{forecast.text}}
            </div>
          </div>
        {{/each}}
      
    */
    {
      "id": "3No+wzuQ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[10,1],[12],[1,[28,[32,0],[[30,0,[\"forecast\",\"icon\"]]],null]],[13],[1,\"\\n        \"],[1,[30,0,[\"forecast\",\"description\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"areas\"],[12],[1,\"\\n        \"],[1,[30,0,[\"forecast\",\"text\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"forecasts\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"forecast\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"description\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"areas\"],[12],[1,\"\\n          \"],[1,[30,1,[\"text\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[\"forecast\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/weather-by-area.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Weather;
});